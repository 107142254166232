<template>
  <div>
    <eden-page-header :title="'Order Fulfilment'" />
    <orders-schedule-meal :allow-marking="true" />
  </div>
</template>

<script>
import OrdersScheduleMeal from "@/components/Orders/Schedule/Meal/OrdersScheduleMeal";

export default {
  name: "OfaOrdersIndex",
  components: { OrdersScheduleMeal },
  data() {
    return {};
  },
};
</script>
